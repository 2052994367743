import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  cursor: none;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;
  padding: 0 20px;
  overflow: hidden;
  border-radius: 50px;

  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
`
