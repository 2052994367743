import styled, { css } from 'styled-components'
import { Body2 } from '../../../theme/typography'
import Image from '../../Image'

const ImageSize = 60

type ContainerProps = {
  isActive: boolean
  width: number
  isMobile: boolean
}

const highlightedContainerCss = css`
  border: 2px solid var(--accent);
  background-color: transparent;
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${ImageSize / 2}px;
  justify-content: space-between;
  padding: 40px 30px;
  position: relative;
  border: 2px solid transparent;
  min-height: 450px;
  cursor: pointer;
  pointer-events: auto;
  transition: background-color ease-in-out 200ms;
  background-color: var(--background-variant);
  border-radius: 8px;
  width: ${({ width = 'initial' }) => width}px;

  ${({ isActive, isMobile }) => (isActive || isMobile) && highlightedContainerCss}
`

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const Quote = styled(Body2)`
  font-size: 1.8rem;
  line-height: 28px;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`

export const Author = styled(Body2)`
  font-size: 1.8rem;
  font-family: 'Aeonik-Bold';
  line-height: 1.2;
`

export const JobTitle = styled(Body2)`
  font-size: 1.6rem;
  line-height: 1.4;
  opacity: 0.7;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ProfilePic = styled(Image)`
  height: ${ImageSize}px;
  width: ${ImageSize}px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
`

export const QuoteBadgeWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--accent);
  height: ${ImageSize}px;
  width: ${ImageSize}px;
  position: absolute;
  inset: 0 38px auto auto;
  transform: translateY(-50%);
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
