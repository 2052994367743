import React, { FC } from 'react'
import { Slide } from 'react-awesome-reveal'
import { Col, Container, Row } from 'react-grid-system'
import ChatBubble from '../../assets/svg/ChatBubble'
import { Spacer } from '../../theme/base'
import { Body1, Headline1 } from '../../theme/typography'
import Button from '../Button'
import CustomLink from '../__general/CustomLink/CustomLink'

type Props = {}

const SayHi: FC<Props> = () => {
  return (
    <Slide direction="left" triggerOnce>
      <Container>
        <Row>
          <Col lg={6}>
            <Headline1>
              Say Hej, Hi or Hola.
              <br />
              We speak a few languages.
            </Headline1>
            <Spacer h16 />
            <Body1>
              We’re not a strategy consultancy, a design studio, an app development company, but we
              do all of these things!
              <br />
              <br />
              We create digital solutions to help businesses thrive. Do you need our expertise?
            </Body1>
            <Spacer h40 />
            <div style={{ float: 'left' }}>
              <CustomLink to="/contact" style={{ width: 'fit-content' }}>
                <Button title="Let's talk" Icon={ChatBubble} />
              </CustomLink>
            </div>
          </Col>
        </Row>
      </Container>
    </Slide>
  )
}

export default SayHi
