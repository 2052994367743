import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

export const Container = styled.div`
  max-width: 2560px;
  margin: 0 auto;

  ${breakpoints.mobile} {
    padding: 0;
  }
`
