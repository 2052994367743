import styled from 'styled-components'
import { breakpoints } from '../../../theme/mediaBreakpoints'

const dotSize = 16

export const NavContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 20px;
  align-items: center;

  ${breakpoints.mobile} {
    padding-right: 0;
  }
`

export const NavBtnContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-left: 20px;
`

const navButtonSize = 72

export const NavBtnWrapper = styled.div<{ isDisabled?: boolean }>`
  background-color: var(--background-variant);
  border-radius: 8px;
  min-height: ${navButtonSize}px;
  min-width: ${navButtonSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  opacity: ${({ isDisabled = false }) => (isDisabled ? 0.4 : 1)};

  ${breakpoints.mobile} {
    display: none;
  }
`
export const DotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  ${breakpoints.mobile} {
    margin: 0 auto;
  }
`

type DotProps = {
  active: boolean
}

export const Dot = styled.div<DotProps>`
  cursor: pointer;
  height: ${dotSize}px;
  width: ${dotSize}px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? 'var(--accent)' : 'var(--background-variant)')};
`
