import { graphql } from 'gatsby'
import * as React from 'react'
import { Visible } from 'react-grid-system'
import { Helmet } from 'react-helmet'
import ConceptPreviewList from '../components/ConceptPreviewList/ConceptPreviewList'
import Footer from '../components/Footer'
import Header from '../components/Header/Header'
import LandingHero from '../components/LandingHero'
import SayHi from '../components/SayHi'
import TestimonialCarousel from '../components/TestimonialCarousel'
import '../css/index.css'
import { Spacer } from '../theme/base'
import { Quote } from '../types'
import { parseContentfulFileUrl } from '../util/helpers'

export const query = graphql`
  query {
    contentfulAsset(title: { eq: "Blob2" }) {
      fixed(height: 716) {
        srcWebp
      }
    }
    allContentfulConcept {
      edges {
        node {
          client {
            id
            slug
          }
          quotes {
            quote {
              quote
            }
            isClient
            name
            title
            image {
              fixed(height: 400) {
                srcWebp
              }
            }
            contentful_id
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const clientQuotes: Quote[] = []

  data.allContentfulConcept.edges.forEach((edge, i) => {
    const slug = edge.node.client.slug
    edge.node.quotes.forEach((quote: Quote) => {
      if (quote.isClient) clientQuotes.push({ ...quote, slug })
    })
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redmind</title>
        <meta
          name="description"
          content="We're a digital product studio partnering with ambitious companies to create valuable digital products. Our studio envisions, designs, builds, and launches products that shape the future."
        />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Header />
        <LandingHero />
        <ConceptPreviewList />
        <Spacer size="large" />
        <TestimonialCarousel quotes={clientQuotes} />
        <Spacer size="large" />
        <Spacer h48 />
        <div style={{ position: 'relative' }}>
          <SayHi />
          <Spacer size="large" />
          <Visible lg xl xxl>
            <div
              style={{
                position: 'absolute',
                bottom: -4,
                right: 0,
                zIndex: -10,
              }}
            >
              <img alt="" src={parseContentfulFileUrl(data.contentfulAsset.fixed.srcWebp)} />
            </div>
          </Visible>
        </div>
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
