import React, { FC } from 'react'
import ArrowRight from '../../assets/svg/ArrowRight'
import { Spacer } from '../../theme/base'
import { Body1 } from '../../theme/typography'

type Props = {}

const ClickableConceptCursor: FC<Props> = () => {
  return (
    <div className="d-flex align-items-center" style={{ transform: 'scale(0.9)' }}>
      <Body1 bold color="var(--background)">
        Read more
      </Body1>
      <Spacer w08 />
      <ArrowRight />
    </div>
  )
}

export default ClickableConceptCursor
