import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { Spacer } from '../../theme/base'
import { Quote } from '../../types'
import CarouselNavigation from '../__general/CarouselNavigation'
import TestimonialCarouselCard from '../__general/TestimonialCarouselCard'
import * as S from './styled'

type Props = {
  quotes: Quote[]
}

const scrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'end',
}

const carouselGap = 32
const cardWidth = 350
const maxNumberOfCards = 4

const getCarouselWidth = (isMobile: boolean, clientWidth: number, itemAmount: number) => {
  const carouselMargin = isMobile ? 10 : 75
  const numberOfCards = Math.min(
    Math.min(itemAmount, maxNumberOfCards),
    Math.floor((clientWidth - carouselMargin * 2) / cardWidth),
  )
  const carouselWidth = carouselGap * (numberOfCards - 1) + cardWidth * numberOfCards

  return carouselWidth
}

const TestimonialCarousel: FC<Props> = ({ quotes }) => {
  const quoteListRef = useRef<HTMLDivElement | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const nodeRefs = useRef<HTMLDivElement[]>([])
  const [clientWidth, setClientWidth] = useState(() => {
    if (typeof window !== 'undefined') return window.innerWidth
    return 0
  })
  const isMobile = useIsMobile()

  useEffect(() => {
    if (typeof window === 'undefined') return
    const handleResize = () => setClientWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!isMobile) return

    const newObserver = new IntersectionObserver(callback, {
      root: quoteListRef.current,
      threshold: 0.6,
    })

    for (const node of nodeRefs.current) newObserver.observe(node)

    return () => newObserver.disconnect()
  }, [isMobile])

  const carouselWidth = getCarouselWidth(isMobile, clientWidth, quotes.length)

  const addNodeRef = useCallback((node: HTMLDivElement) => nodeRefs.current.push(node), [])

  const callback = (entries: IntersectionObserverEntry[]) => {
    const entry = entries.find(entry => entry.isIntersecting)
    if (entry) {
      const entryIndex = (nodeRefs.current as Element[]).indexOf(entry.target)
      setActiveIndex(entryIndex)
    }
  }

  const scrollTo = (index: number) => nodeRefs.current[index]?.scrollIntoView(scrollOptions)

  const goToPrevious = () => {
    if (activeIndex === 0) return
    const previousIndex = activeIndex - 1
    setActiveIndex(previousIndex)
    scrollTo(previousIndex)
  }

  const goToNext = () => {
    if (activeIndex === nodeRefs.current.length - 1) return
    const nextIndex = activeIndex + 1
    setActiveIndex(nextIndex)
    scrollTo(nextIndex)
  }

  const goToIndex = (index: number) => {
    if (isMobile) return
    scrollTo(index)
    setActiveIndex(index)
  }

  const testimonials = quotes.map((quote, i) => (
    <S.TestimonialWrapper onClick={() => goToIndex(i)} key={quote.contentful_id} ref={addNodeRef}>
      <TestimonialCarouselCard width={cardWidth} isActive={i === activeIndex} quote={quote} />
    </S.TestimonialWrapper>
  ))

  return (
    <S.Container>
      <div style={{ width: carouselWidth }}>
        <S.Carousel
          gap={carouselGap}
          carouselWidth={carouselWidth}
          isMobile={isMobile}
          ref={quoteListRef}
        >
          {testimonials}
        </S.Carousel>
        <Spacer h32 />
        <S.CarouselNavigationWrapper>
          <CarouselNavigation
            activeIndex={activeIndex}
            maxIndex={quotes.length - 1}
            goToIndex={goToIndex}
            goToNext={goToNext}
            goToPrevious={goToPrevious}
          />
        </S.CarouselNavigationWrapper>
      </div>
    </S.Container>
  )
}

export default TestimonialCarousel
