import styled, { keyframes } from 'styled-components'
import { completeAnimations } from '../../theme/animations'
import { breakpoints } from '../../theme/mediaBreakpoints'
import { title1Styles, typographyBase } from '../../theme/typography'

export const Container = styled.div`
  height: 100vh;
  min-height: 800px;
  width: 100%;
  padding-top: 80px;
`

export const Video = styled.video`
  width: 100%;
  object-fit: cover;
  transform: scale(1.38);
  ${breakpoints.mobile} {
    width: 70%;
  }
`

const weightAnimation = keyframes`
  from {
    font-variation-settings: 'wght' 20;
  }
  to {
    font-variation-settings: 'wght' 200;
  }
`

export const HeadlineContainer = styled.div`
  h1 {
    opacity: 0;
    ${completeAnimations.animSlideUp3D};
    animation-delay: inherit;
    transition: color 0.2s ease-in-out, letter-spacing 0.2s ease-in-out;
    user-select: none;
    width: fit-content;
  }

  ${breakpoints.notMobile} {
    h1:hover {
      color: var(--accent);
      letter-spacing: 20px;
    }
  }

  .animated-title-0 {
    animation-delay: 0.1s;
  }

  .animated-title-1 {
    animation-delay: 0.2s;
  }
`

export const WeightAnimation = styled.span`
  font-variation-settings: 'wght' 20;

  animation: ${weightAnimation} 0.8s ease-out forwards;
`

export const WeTitle = styled.h1`
  ${typographyBase}
  ${title1Styles}
  font-variation-settings: 'wght' 20;

  ${breakpoints.mobile} {
    font-variation-settings: 'wght' 60;
  }
`
