import React, { FC } from 'react'
import QuotationMark from '../../../assets/svg/QuotationMark'
import useIsMobile from '../../../hooks/useIsMobile'
import { Spacer } from '../../../theme/base'
import { Quote } from '../../../types'
import Button from '../../Button'
import CustomLink from '../../__general/CustomLink'
import * as S from './styled'

type Props = {
  quote: Quote
  isActive: boolean
  width: number
}

const TestimonialCarouselCard: FC<Props> = ({
  quote: { image, name, quote, title, slug },
  isActive,
  width,
}) => {
  const isMobile = useIsMobile()

  const { srcWebp: src } = image.fixed
  const isButtonActive = isActive || isMobile

  return (
    <S.Container width={width} isActive={isActive} isMobile={isMobile}>
      <div>
        <S.QuoteBadgeWrapper>
          <QuotationMark />
        </S.QuoteBadgeWrapper>
        <S.DetailsWrapper>
          <S.ProfilePic src={src} rounded={false} />
          <div>
            <S.Author>{name}</S.Author>
            <Spacer h08 />
            <S.JobTitle>{title}</S.JobTitle>
          </div>
          <Spacer h36 />
        </S.DetailsWrapper>
        <Spacer h12 />
        <S.Quote>{quote.quote}</S.Quote>
      </div>
      <S.ButtonWrapper onClick={e => e.stopPropagation()}>
        <CustomLink to={`/concept/${slug}`}>
          <Button
            variant={isButtonActive ? 'default' : 'secondary'}
            size="small"
            title="Read more"
          />
        </CustomLink>
      </S.ButtonWrapper>
    </S.Container>
  )
}

export default TestimonialCarouselCard
