import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

export const Container = styled.div`
  padding: 0 min(2vw, 40px);

  .clickable-image {
    height: 0;
    padding-top: ${(9 / 21) * 100}%;

    &__image {
      position: absolute;
      top: 0;
      left: 0;
    }

    ${breakpoints.tablet} {
      padding-top: ${(9 / 16) * 100}%;
    }
  }
`

export const ContentContainer = styled.div`
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoints.mobile} {
    padding: 0 4px;
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 10px;
    }
  }

  & > *:first-child {
    padding-right: 10px;
  }
`
