import React, { FC } from 'react'
import Image from '../Image'
import * as S from './styled'

type Props = { clickable?: boolean; alt?: string; preview?: string; rounded?: boolean } & any

const ClickableImage: FC<Props> = ({
  src,
  aspectRatio,
  clickable = true,
  preview,
  alt,
  rounded,
  ...props
}) => {
  return (
    <S.Container className="clickable-image" rounded={!!rounded} clickable={clickable} {...props}>
      <Image
        rounded={rounded}
        preview={preview}
        src={src}
        className="clickable-image__image"
        alt={alt}
      />
      {clickable && <S.Overlay rounded={!!rounded} className="clickable-image__overlay" />}
    </S.Container>
  )
}

export default ClickableImage
