import React, { useEffect, useRef, useState } from 'react'
import { Portal } from 'react-portal'
import CustomCursor, { customCursorId } from './CustomCursor'

const getCursor = () => {
  const cursor = document.getElementById(customCursorId) as any
  return cursor
}

const useCustomCursor = () => {
  const [showCustomHover, setShowCustomHover] = useState(false)
  const id = useRef(Date.now().toString() + Math.random().toString()).current
  const mousePosRef = useRef<MouseEvent>()

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      mousePosRef.current = e
    }

    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [])

  useEffect(() => {
    const onScroll = () => {
      const element = document.getElementById(id)
      const isHovering = element?.matches(':hover')

      if (isHovering) {
        if (!mousePosRef.current) return
        const { clientX, clientY } = mousePosRef.current
        updateCursorPosition(clientX, clientY)
      }
      if (isHovering && !showCustomHover) {
        setShowCustomHover(true)
      }
      if (!isHovering && showCustomHover) {
        setShowCustomHover(false)
      }
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [showCustomHover])

  const updateCursorPosition = (x: number, y: number) => {
    const cursor = getCursor()
    if (!cursor) return

    cursor.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 50%))`
  }

  const onMouseMove: React.MouseEventHandler<HTMLDivElement> = ({ clientX, clientY }) => {
    updateCursorPosition(clientX, clientY)
  }
  const onMouseEnter = () => {
    setShowCustomHover(true)
  }
  const onMouseLeave = () => {
    setShowCustomHover(false)
  }
  const eventHandlers = {
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    id,
  }

  const CustomCursorWrapper = ({ children }) => {
    return typeof window === 'undefined' ? null : (
      <Portal node={window.document.body}>
        <CustomCursor visible={showCustomHover}>{children}</CustomCursor>
      </Portal>
    )
  }

  return { eventHandlers, CustomCursorWrapper }
}

export default useCustomCursor
