import React, { FC } from 'react'

type Props = {
  color?: string
}

const SmallArrowRight: FC<Props> = ({ color = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
      <path
        d="M12.75 2L21.75 11L12.75 20M20.5 11H2.25"
        stroke={color}
        strokeWidth="3.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmallArrowRight
