import React, { FC } from 'react'
import useMultiLineTypeWriter from './useMultiLineTypeWriter'

type Props = {
  lines: string[][]
  renderLine(text: string, index: number): any
}

const MultiLineTypeWriter: FC<Props> = ({ lines, renderLine }) => {
  const { currentLine } = useMultiLineTypeWriter({ lines })

  if (typeof renderLine !== 'function' || !lines?.length || !currentLine.length) {
    return null
  }

  return <>{currentLine.map(renderLine)}</>
}

export default MultiLineTypeWriter
