import { graphql, useStaticQuery } from 'gatsby'
import React, { FunctionComponent } from 'react'
import Reveal from 'react-awesome-reveal'
import { Col, Container, Row, Visible } from 'react-grid-system'
import animations from '../../theme/animations'
import { Spacer } from '../../theme/base'
import { Headline1, Headline2, Title1 } from '../../theme/typography'
import { parseContentfulFileUrl } from '../../util/helpers'
import MultiLineTypeWriter from '../MultiLineTypeWriter/MultiLineTypeWriter'
import * as S from './styled'

type Props = {}

const query = graphql`
  query {
    contentfulAsset(title: { eq: "blobmp4" }) {
      file {
        url
      }
    }
  }
`

const LINES = [
  ['Build', 'Apps.'],
  ['Solve', 'Problems.'],
  ['Make', 'Designs.'],
  ['Elevate', 'Products.'],
]

const LandingHero: FunctionComponent<Props> = () => {
  const { contentfulAsset } = useStaticQuery(query)

  return (
    <S.Container>
      <Container style={{ height: '100%' }}>
        <Row
          align="center"
          style={{
            height: '100%',
          }}
        >
          <Col lg={8} xxl={6} style={{ zIndex: 10 }}>
            <S.HeadlineContainer>
              <S.WeTitle>We</S.WeTitle>
              <MultiLineTypeWriter
                renderLine={(text, index) => (
                  <S.WeightAnimation key={index} className={`animated-title-${index}`}>
                    <Title1>{text}</Title1>
                  </S.WeightAnimation>
                )}
                lines={LINES}
              />
            </S.HeadlineContainer>
            <Spacer exact={50} />
            <Reveal triggerOnce keyframes={animations.slideUpFadeIn} delay={420}>
              <Headline1 style={{ fontFamily: 'Aeonik-Regular' }}>
                Redmind, a digital product studio in Stockholm, merges professional expertise with a
                personal touch, putting heart and brain into every project.
              </Headline1>
            </Reveal>
          </Col>
          <Visible lg xl xxl>
            <Col align="center" lg={4} xxl={6}>
              <S.Video autoPlay loop muted>
                <source src={parseContentfulFileUrl(contentfulAsset.file.url)}></source>
                <track
                  src="../../assets/captions/blob.vtt"
                  kind="captions"
                  label="english_captions"
                />
              </S.Video>
            </Col>
          </Visible>
        </Row>
      </Container>
    </S.Container>
  )
}

export default LandingHero
