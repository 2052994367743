import styled from 'styled-components'
import { breakpoints } from '../../theme/mediaBreakpoints'

type CarouselProps = {
  isMobile: boolean
  carouselWidth: number
  gap: number
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoints.mobile} {
    align-items: center;
  }
`

export const Carousel = styled.div<CarouselProps>`
  display: flex;
  overflow-x: hidden;
  overflow-y: visible;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  pointer-events: none;
  gap: ${({ gap = 'initial' }) => gap}px;
  width: ${({ carouselWidth = 'initial' }) => carouselWidth}px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoints.mobile} {
    overflow-x: scroll;
  }
`

export const TestimonialWrapper = styled.div`
  scroll-snap-align: start;
`

export const CarouselNavigationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
