import styled, { css } from 'styled-components'
import { roundedImageCss } from '../Image/styled'

const animatedHover = css`
  cursor: none;
  &:hover {
    img {
      transform: scale(1);
    }

    div {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`

type ContainerProps = {
  aspectRatio?: number
  clickable: boolean
  rounded: boolean
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: default;

  img {
    transition: all 500ms, filter 150ms;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.05);
  }

  ${({ clickable }) => clickable && animatedHover}
  ${({ rounded }) => rounded && roundedImageCss}
`

type OverlayProps = {
  rounded: boolean
}

export const Overlay = styled.div<OverlayProps>`
  background: rgba(0, 0, 0, 0);
  transition: background ease-in-out 0.15s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ rounded }) => rounded && roundedImageCss}
`
