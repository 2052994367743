import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import Reveal from 'react-awesome-reveal'
import animations from '../../theme/animations'
import { Spacer } from '../../theme/base'
import { Concept } from '../../types'
import ConceptPreview from '../ConceptPreview/ConceptPreview'
import * as S from './styled'

type Props = {}

const query = graphql`
  query {
    contentfulHomePage {
      concepts {
        client {
          name
          tags
          slug
          slogan
          coverImage {
            fixed(height: 1000) {
              src
              srcWebp
            }
          }
          coverThumbnail: coverImage {
            fixed(height: 100) {
              src
              srcWebp
            }
          }
        }
        contentful_id
      }
    }
  }
`

const ConceptPreviewList: FC<Props> = () => {
  const { contentfulHomePage } = useStaticQuery(query)

  const concepts = contentfulHomePage.concepts.map(
    (concept: Concept, index: number, arr: Concept[]) => (
      <div key={concept.contentful_id}>
        <Reveal triggerOnce fraction={0.3} keyframes={animations.slideUp} duration={500}>
          <ConceptPreview concept={concept} />
        </Reveal>
        {index < arr.length - 1 && <Spacer size="large" />}
      </div>
    ),
  )

  return <S.Container>{concepts}</S.Container>
}

export default ConceptPreviewList
