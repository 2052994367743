import React, { FC } from 'react'

type Props = {
  color?: string
}

const SmallArrowLeft: FC<Props> = ({ color = '#000000' }) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 20L2.25 11L11.25 2M3.5 11L21.75 11"
        stroke={color}
        strokeWidth="3.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SmallArrowLeft
