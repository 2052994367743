import React, { FC } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Spacer } from '../../theme/base'
import { Body1, Body2 } from '../../theme/typography'
import { Concept } from '../../types'
import { parseContentfulFileUrl } from '../../util/helpers'
import ClickableConceptCursor from '../ClickableConceptCursor/ClickableConceptCursor'
import ClickableImage from '../ClickableImage/ClickableImage'
import useCustomCursor from '../CustomCursor/useCustomCursor'
import CustomLink from '../__general/CustomLink/CustomLink'
import PillList from '../__general/PillList/'
import * as S from './styled'

type Props = {
  concept: Concept
}

const ConceptPreview: FC<Props> = ({ concept }) => {
  const { eventHandlers, CustomCursorWrapper } = useCustomCursor()
  const { coverImage, name, slug, slogan, tags, coverThumbnail } = concept.client

  const { src, srcWebp } = (coverImage as any).fixed
  const imageSrc = parseContentfulFileUrl(srcWebp || src)

  return (
    <>
      <S.Container>
        <Container fluid>
          <Row>
            <Col>
              <CustomLink to={`/concept/${slug}`}>
                <ClickableImage
                  rounded
                  preview={coverThumbnail}
                  alt={name}
                  src={imageSrc}
                  {...eventHandlers}
                />
              </CustomLink>
              <Spacer h16 />
              <S.ContentContainer>
                <div>
                  <Body1 bold>{name}</Body1>
                  <Spacer h08 />
                  <Body2>{slogan}</Body2>
                </div>
                <div>
                  <Spacer h04 />
                  <PillList pills={tags} />
                </div>
              </S.ContentContainer>
            </Col>
          </Row>
        </Container>
      </S.Container>
      <CustomCursorWrapper>
        <ClickableConceptCursor />
      </CustomCursorWrapper>
    </>
  )
}

export default ConceptPreview
