import React, { FC } from 'react'
import * as S from './styled'

type Props = {
  visible: boolean
}

export const customCursorId = 'custom-cursor'

const CustomCursor: FC<Props> = ({ visible, children }) => {
  if (!visible) return null
  return <S.Container id={customCursorId}>{children}</S.Container>
}

export default CustomCursor
