import React, { FC } from 'react'
import SmallArrowLeft from '../../../assets/svg/SmallArrowLeft'
import SmallArrowRight from '../../../assets/svg/SmallArrowRight'
import * as S from './styled'

type Props = {
  activeIndex: number
  maxIndex: number
  goToPrevious(): void
  goToNext(): void
  goToIndex(i: number): void
}

const CarouselNavigation: FC<Props> = ({
  goToNext,
  goToPrevious,
  activeIndex,
  goToIndex,
  maxIndex,
}) => {
  const dots = Array(maxIndex + 1)
    .fill(null)
    .map((_, i) => <S.Dot key={i} active={i === activeIndex} onClick={() => goToIndex(i)} />)

  const firstIndexIsActive = activeIndex === 0
  const lastIndexIsActive = activeIndex === maxIndex

  return (
    <S.NavContainer>
      <S.DotContainer>{dots}</S.DotContainer>
      <S.NavBtnContainer>
        <S.NavBtnWrapper onClick={goToPrevious} isDisabled={firstIndexIsActive}>
          <SmallArrowLeft color="#1F2125" />
        </S.NavBtnWrapper>
        <S.NavBtnWrapper onClick={goToNext} isDisabled={lastIndexIsActive}>
          <SmallArrowRight color="#1F2125" />
        </S.NavBtnWrapper>
      </S.NavBtnContainer>
    </S.NavContainer>
  )
}

export default CarouselNavigation
